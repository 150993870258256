<template lang="pug">
    v-flex(xs12)
        h3 {{ $t('MAPA DE CRECIMIENTO POR REGIONES') }}
        br
        v-btn(color="primary" small @click="filterShow = !filterShow") {{$t(!filterShow ? 'Ver filtro' : 'Ocultar filtro')}}
        v-form
            v-layout(row wrap v-if="filterShow")
                v-flex(xs12 sm6)
                    v-text-field(type="number" v-model="filter.goal" :label="$t('Meta de crecimiento (%)')")
                v-flex(xs12 sm6)
                    v-dialog.mb-3(
                        ref='dialogPerStart' 
                        v-model='modalDateStart' 
                        lazy 
                        full-width 
                        width='290px' 
                        :return-value.sync='filter.from'
                    )
                        v-text-field(slot='activator' :label='$t("Fecha inicial")' v-model='filter.from' readonly)
                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                            v-spacer
                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{$t('Cancelar')}}
                            v-btn(flat color='primary' @click.stop='$refs.dialogPerStart.save(filter.from)') {{$t('OK')}}
                v-flex(xs12 sm6)
                    v-dialog.mb-4(
                        ref='dialogPerEnd' 
                        v-model='modalDateEnd' 
                        lazy 
                        full-width 
                        width='290px' 
                        :return-value.sync='filter.to'
                    )
                        v-text-field(slot='activator' :label='$t("Fecha final")' v-model='filter.to' readonly)
                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                            v-spacer
                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{$t('Cancelar')}}
                            v-btn(flat color='primary' @click.stop='$refs.dialogPerEnd.save(filter.to)') {{$t('OK')}}
                v-flex.ma-0.pa-0(xs12)
                v-flex(xs12 sm6)
                    v-text-field(v-model="filter.query" :label="$t('Buscar por nombre, correo electrónico o teléfono')")
                v-flex(xs12 sm6)
                    v-select(
                        v-model="filter.network"
                        :items='networks'
                        item-text="name"
                        item-value="uuid"
                        :label='$t("Red")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                    )
                v-flex(xs12 sm6)
                    v-select(
                        v-model="filter.country"
                        :items='countries'
                        item-text="name"
                        item-value="uuid"
                        :label='$t("País")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                        @input="filter.state='',filter.city='',filter.zones=[],states=[],cities=[],zones=[],zonei=-1,fetchState(filter.country, 'states'),fetchRegion()"
                    )
                v-flex(xs12 sm6)
                    v-select(
                        v-model="filter.state"
                        :items='states'
                        item-text="name"
                        item-value="uuid"
                        :label='$t("Estado")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                        @input="filter.city='',filter.zones=[],cities=[],zones=[],zonei=-1,fetchCity(filter.state, 'cities')"
                    )
                v-flex(xs12 sm6)
                    v-select(
                        small
                        v-model="filter.city"
                        :items='cities'
                        item-text="name"
                        item-value="uuid"
                        :label='$t("Ciudad")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                        @change="zonei=-1"
                        @input="filter.zones=[],zones=[],zonei=-1,fetchZone(0, filter.city, 'zonei', 'zones', filter.zones)"
                    )
                template(v-if="zones.length")
                    v-flex(xs12 sm6 v-for="(zone, i) in zones" :key="'z'+i")
                        v-select(
                            v-model="filter.zones[i]"
                            :items='zone',
                            item-value="uuid",
                            item-text="name", 
                            :label='zone[0].division', 
                            append-icon="keyboard_arrow_down", 
                            autocomplete
                            :filter="vFilter"
                            @change="zonei=i"
                            @input="fetchZone(filter.zones[i], filter.city, 'zonei', 'zones', filter.zones)"
                        )
                //- Regiones especiales
                v-flex(xs12 sm6 v-if="filter.country")
                    v-select(
                        small
                        v-model="filter.region"
                        :items='region'
                        :label='$t("Región especial")' 
                        item-text="name"
                        item-value="uuid"
                        append-icon="keyboard_arrow_down"
                        autocomplete
                        :filter="vFilter"
                    )
                v-flex(xs12 sm6)
                    v-select(
                        v-model="filter.gender"
                        :items='gender'
                        item-text="text"
                        item-value="id"
                        :label='$t("Género")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                    )
                v-flex(xs12 sm6)
                    v-select(
                        v-model="filter.total_cond"
                        :items='conditionalNumber'
                        item-text="text"
                        item-value="id"
                        :label='$t("Total de usuarios por")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                    )
                    template(v-if="filter.total_cond")
                        v-text-field(v-if="filter.total_cond != 'between'" v-model="filter.total[0]" :label="$t('Total de usuarios')")
                        template(v-else)
                            v-text-field(v-model="filter.total[0]" type="number" min="0" :label="$t('Total de usuarios (Desde)')")
                            v-text-field(v-model="filter.total[1]" type="number" min="0" :label="$t('Total de usuarios (Hasta)')")
                .btns.btns-right
                    v-btn(color="primary" small @click="selected={},items=[],inFilterMap=true,fetchData()") {{$t('Filtrar')}}
                    v-btn(color="primary" :loading="loadingBtn" small @click="download") {{$t('Generar reporte')}}
                    v-btn(color="secondary" small @click="inFilterMap=true,resetData()") {{$t('Restablecer')}}
        v-layout(row wrap)
            v-flex.ml-2(xs12)
                span.d-block {{ $t('Meta de crecimiento:') }} 
                    b {{ filter.goal }}%
                span.d-block {{ $t('Fecha inicial:') }} 
                    b {{ filter.from }}
                span.d-block {{ $t('Fecha final:') }} 
                    b {{ filter.to }}
            v-flex(xs12 sm6)
                //-
                    h4.my-2(v-if="type == 1") {{ $t('Estas viendo países del mundo') }}
                    h4.my-2(v-if="type == 2") {{ $t('Estas viendo estados de ') }} {{ parent.name }}
                    h4.my-2(v-if="type == 3") {{ $t('Estas viendo ciudades de ') }} {{ parent.name }}
                    h4.my-2(v-if="type == 4") {{ $t('Estas viendo la ciudad de ') }} {{ parent.name }}
                    h4.my-2(v-if="type == 5") {{ $t('Estas viendo la zona de ') }} {{ parent.name }}
                l-map(v-if="showMap" :options="{attributionControl:false}" :center="center" :zoom="zoom" @moveend="updateMap" ref="map" style="min-height:400px;z-index:1")
                    l-control(position="bottomright" v-if="loadData")
                        v-progress-circular(indeterminate color="primary") 
                    template(v-for="(dat, i) in data")
                        l-marker(
                            v-if="dat.uuid != null"
                            :opacity="1"
                            :fillOpacity="0.5"
                            :color="dat.color"
                            :weight="2"
                            :fillColor="dat.fillColor"
                            :lat-lng="[dat.lat, dat.lng]"
                            layerType="marker"
                            @click="fetchListByMarker(dat)"
                        )
                            l-icon
                                i.icon.material-icons.red--text.x(:style="{'font-size': getRadius(dat.to) + 'px'}" v-if="dat.per < filter.goal") close
                                i.icon.material-icons.green--text.x(:style="{'font-size': getRadius(dat.to) + 'px'}" v-else) done
                            l-tooltip
                                span {{ $t('Total de usuarios en') }} {{ dat.name.trim() }}&nbsp;
                                b {{ dat.to.toLocaleString('es-CO') }}
                                small.d-block {{ $t('% de crecimiento') }} {{ parseFloat(dat.per).toFixed(2) }}%
            v-flex.mt-3(xs12 sm6)
                h4.my-2 {{ $t('Listado de usuarios') }}
                .text-xs-center(xs12 v-if="loadList")
                    v-progress-circular(indeterminate color="primary")
                .resume-table-map(v-else)
                    v-data-table(
                        ref="table"
                        :headers='headers'
                        :items='items'
                        hide-actions
                    )
                        template(slot="no-results")
                            | {{$t('Seleccione una ubicación en el mapa')}}
                        template(slot="no-data")
                            | {{$t('Seleccione una ubicación en el mapa')}}
                        template(slot='headers' slot-scope='props')
                            tr
                                th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                    span.subheading {{ $t(header.text) }}
                        template(slot='items' slot-scope='props')
                            tr(slot="activator")
                                td.cur-p(align="left" @click="$refs.referMap.open(props.item)") {{ props.item.names }}
                                td(align="left") 
                                    v-tooltip(top color="white" content-class="tooltip-map-list")
                                        div.cur-p(slot="activator" style="width:100%")
                                            div(:class="{green: filter.goal <= props.item.per, red: filter.goal >= props.item.per}" :style="{'height': '10px', 'width': itemBars[props.index]+'%'}")
                                        div
                                            h4 {{ props.item.names }}
                                            p.mt-1.mb-2
                                                span.d-block {{ $t('Crecio') }} {{ parseFloat(props.item.per).toFixed(2) }}% {{ $t('desde') }} {{ filter.from }} {{ $t('hasta') }} {{ filter.to }}  
                                                span.d-block {{ $t('Empezó con') }} {{ props.item.total_from }} {{ $t('y llegó a') }} {{ props.item.total_to }}  
                                            span.d-block {{ $t('Número de referidos') }}: {{ props.item.total.toLocaleString() }}
                                            span.d-block {{ $t('Número de referidos directos') }}: {{ props.item.totalshare.toLocaleString() }}
                                            span.d-block {{ $t('Género') }}: {{ props.item.gender }}
                                            span.d-block {{ $t('País') }}: {{ props.item.country }}
                                            span.d-block {{ $t('Estado') }}: {{ props.item.state }}
                                            span.d-block {{ $t('Ciudad') }}: {{ props.item.city }}
                                            span.d-block(v-for="(zone, i) in props.item.zones" :key="'z'+i") {{ $t(zone.division) }}: {{ zone.name }}
                                td(align="left") {{ props.item.network }}
                                td(align="left") {{ props.item.country }}
                                td(align="left") {{ props.item.state }}
                                td(align="left") {{ props.item.city }}
                    v-flex.text-xs-center(v-if="items.length && !noLoadMore && items.length >= limit" xs12)
                        v-btn.primary(depressed small @click="infinite" :loading="loadInfinite") {{ $t('Ver más') }}
            v-flex.mt-5(xs12 sm6 v-if="Object.keys(selected).length")
                h4.my-2 {{ $t('Comportamiento en el tiempo') }} - {{ selected.name }}
                .text-xs-center.mt-3(xs12 v-if="loadListByDate")
                    v-progress-circular(indeterminate color="primary")
                canvas.mt-3(v-else id="list-by-date" height="150px")
        goal-map-refer(ref="referMap" :filter="filter")
</template>

<script>

    import _m from 'lodash/merge'
    import _d from 'lodash/debounce'
    import fn from 'mixins/fn'
    import moment from 'moment'
    import numeral from 'numeral'
    import config from 'src/config'
    import GoalMapRefer from 'components/dashboard/GoalMapRefer'

    let from = moment().subtract(1, 'month').format('YYYY-MM-DD')
    let to   = moment().format('YYYY-MM-DD') 

    export default {
        components: {
            GoalMapRefer
        },
        mixins: [fn],
        props: {
            parentFilter: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
            return {
                filterShow: false,
                filterSchema: {
                    query     : '',
                    network   : [],
                    country   : '',
                    state     : '',
                    city      : '',
                    region    : '',
                    zones     : [],
                    gender    : '',
                    age       : '',
                    from      : from,
                    to        : to,
                    bb        : {},
                    zoom      : 1,
                    goal      : 1,
                    total_cond: '',
                    total     : ['','']
                },
                filter : {},
                filterListSchema: {
                    type: '',
                    typeid: '',
                    zones: []
                },
                filterList: {},
                countries: [],
                states   : [],
                cities   : [],
                zones    : [],
                zonei    : -1,
                region   : [],
                networks : [],
                modalDateStart : false,
                modalDateEnd   : false,

                // Mapa
                center       : null,
                zoom         : 1,
                showMap      : false,
                l            : null,
                data         : [],
                type         : 1,
                loadData     : false,

                // Listado
                items: [],
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'names',
                        sortable: false
                    },
                    {
                        text: 'Total',
                        align: 'left',
                        value: 'from',
                        sortable: false
                    },
                    {
                        text: 'Red',
                        align: 'left',
                        value: 'network',
                        sortable: false
                    },
                    {
                        text: 'País',
                        align: 'left',
                        value: 'country',
                        sortable: false
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'state',
                        sortable: false
                    },
                    {
                        text: 'Ciudad',
                        align: 'left',
                        value: 'city',
                        sortable: false
                    }
                ],
                loadList: false,
                loadListByDate: false,
                limit : this.$root.limit,
                offset: 0,
                inFilterMap: false,
                loadInfinite: false,
                noLoadMore: false,
                loadingBtn: false,

                // Región seleccionada
                selected: {},

                // Gráficos
                listByDateChart: null
            }
        },
        computed:{
            maxTotal(){
                return Math.max(...this.data.map(d => d.to))
            },
            gender(){ 
                return [
                    {
                        text: this.$t('Masculino'),
                        id: 1
                    },
                    {
                        text: this.$t('Femenino'),
                        id: 0
                    },
                    {
                        text: this.$t('Otro'),
                        id: 2
                    }
                ]
            },
            ages(){ 
                return [
                    {
                        text: this.$t('0-20'),
                        id: 1
                    },
                    {
                        text: this.$t('21-40'),
                        id: 2
                    },
                    {
                        text: this.$t('41-60'),
                        id: 3
                    },
                    {
                        text: this.$t('61-80'),
                        id: 4
                    },
                    {
                        text: this.$t('Mayor a 80'),
                        id: 5
                    }
                ]
            },
            itemBars(){

                if(!this.items.length){
                    return []
                }

                let maxValue = Math.max(...this.items.map(i => i.per))
                let items = this.items.map((i) => {
                    return Math.round(100 * i.per/maxValue)
                })

                return items
            }
        },
        watch: {
            parentFilter: {
                deep: true,
                handler(){

                    this.fetchNetworks()
                    this.fetchData()
                    this.items = []
                }
            },
            filter: {
                deep: true,
                handler(){

                    if(this.filter.goal < 0){
                        this.filter.goal = 0
                    }
                }
            }
        },
        created(){

            this.fetchNetworks()
            this.fetchCountries()

            this.filter = _m({}, this.filterSchema)
            this.filterList = _m({}, this.filterListSchema)
        },
        async mounted(){

            this.l = require('leaflet')
            await this.getLatLng()

            this.updateMap({target: this.$refs.map.mapObject})
        },
        methods: {

            resetData(){

                this.filter       = _m({}, this.filterSchema)
                this.filterList   = _m({}, this.filterListSchema)
                this.filter.zones = []
                this.filterList.zones = []
                this.zones        = []
                this.states       = []
                this.cities       = []
                this.zonei        = -1
                this.items        = []
                this.selected     = {}
                
                this.fetchData()
            },

            fetchZone(item, cityid, iter, model, filter){

                this[model] = this[iter] == -1 ? [] : this[model].slice(0, this[iter] +1)
                
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid,
                                parent: this[iter] == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if(data.data.length){
                                    filter.push('')
                                    this[model].push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchCountries(){

                this.$api(this, (xhr) => {
                    xhr.get('/country').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(countryid, model = ''){

                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this[model] = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchCity(stateid, model = ''){

                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this[model] = data.data
                        }
                    }).catch(() => {})
                })
            },

            getRadius(total){

                let maxRadius = 50
                let minRadius = 15

                if(total == 0){
                    return minRadius
                }

                let per  = (100 * total)/this.maxTotal
                let calc = (per * maxRadius)/100

                return (calc < minRadius ? minRadius : calc)
            },

            fetchData(reset = true){

                if(reset){
                    this.data = []
                }

                if(this.inFilterMap && this.filter.country){
                    this.filter.zoom = 5
                }
                if(this.inFilterMap && this.filter.state){
                    this.filter.zoom = 8
                }
                if(this.inFilterMap && this.filter.city){
                    this.filter.zoom = 12
                }

                this.loadData = true
                this.$Progress.start()
                this.$api(this, (xhr) => {
                    xhr.get('/dashboard/map/goal', {
                        params:{
                            filter   : JSON.stringify(this.filter),
                            subadmin : JSON.stringify(this.parentFilter.subadmin),
                        }
                    }).then((r) => {

                        let data = r.data
                        this.loadData = false
                        this.$Progress.finish()
                            
                        if(data.response){
                            
                            this.data = data.data.map((d) => {

                                d.color     = '#0094d7'
                                d.fillColor = '#0094d7'
                                return d
                            })

                            if(!this.inFilterMap){
                                return
                            }
                            
                        }else{

                            if(!this.inFilterMap){
                                return
                            }
                        }

                        this.parent = data.parent
                        this.type   = data.type

                        if(data.parent != null && typeof data.parent == 'object' && Object.keys(data.parent).length){

                            const parent = data.parent
                            const latLng = this.l.latLng(parent.lat, parent.lng)
                            let zoom = 0
                            
                            // Estado
                            if(data.type == 2){
                                zoom = 5
                            }

                            // Ciudad
                            if(data.type == 3){
                                zoom = 8
                            }

                            //Zona
                            if(data.type == 4 || data.type == 5){
                                zoom = 11
                            }

                            this.$refs.map.mapObject.setView(latLng, zoom)
                            this.inFilterMap = false

                        }else{

                            if(reset){

                                this.$refs.map.mapObject.setView(this.center, 1)
                                this.inFilterMap = false
                            }
                        }

                    }).catch(() => {
                        this.loadData = false
                        this.$Progress.finish()
                    })
                })
            },

            fetchList(infinite = false){

                if(infinite){
                    this.offset += this.limit
                    this.loadInfinite = true
                }else{
                    this.loadList = true
                    this.items = []
                    this.offset = 0
                    this.$refs.infinite && this.$refs.infinite.reset()
                }

                this.$api(this, (xhr) => {
                    xhr.get('/dashboard/map/goal/list', {
                        params:{
                            filter   : JSON.stringify(_m(this.filter, this.filterList)),
                            limit    : this.limit,
                            offset   : this.offset,
                            subadmin : JSON.stringify(this.parentFilter.subadmin)
                        }
                    }).then((r) => {
                        
                        let data = r.data
                        if(!infinite) this.loadList = false

                        if(infinite){
                            this.loadInfinite = false
                            if(data.response){
                                this.items = this.items.concat(data.data);
                                this.$refs.infinite && this.$refs.infinite.loaded()
                            }else{
                                this.noLoadMore = true
                                this.$refs.infinite && this.$refs.infinite.complete()
                            }
                        }else{
                            this.items = (data.response ? data.data : [])
                        }

                    }).catch(() => {
                        if(!infinite) this.loadList = false
                        if(infinite) this.loadInfinite = false
                    })
                })
            },

            infinite(){
                setTimeout(() => this.fetchList(true) , 1)
            },

            fetchListByDate(){

                this.loadListByDate = true
                
                this.$api(this, (xhr) => {
                    xhr.get('/dashboard/map/goal/list-by-date', {
                        params:{
                            filter   : JSON.stringify(_m(this.filter, this.filterList)),
                            subadmin : JSON.stringify(this.parentFilter.subadmin)
                        }
                    }).then(async (r) => {
                        
                        let data = r.data
                        this.loadListByDate = false

                        let dates = (data.response ? data.data : [])
                        await this.$nextTick()

                        if(dates.length){

                            if(this.listByDateChart != null){
                                this.listByDateChart.destroy()
                            }

                            let ctx = document.getElementById('list-by-date').getContext('2d')
                            this.listByDateChart = new Chart(ctx, {
                                type: 'bar',
                                data: {
                                    labels: dates.map((d) => d.label),
                                    datasets: [
                                        {
                                            type       : 'line',
                                            label      : this.$t('Total de usuarios'),
                                            borderColor: '#169cb3',
                                            backgroundColor: '#169cb3',
                                            data       : dates.map(d => d.total),
                                            fill       : false,
                                            lineTension: 0.3,
                                            pointBackgroundColor: '#169cb3',
                                            borderWidth: 2,
                                            pointBorderWidth: 0,
                                            pointRadius: 3,
                                            pointHoverRadius: 4
                                        }
                                    ]
                                },
                                options:{
                                    scales:{
                                        xAxes:[{
                                            gridLines:{
                                                display: false
                                            }
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                userCallback(label, index, labels){
                                                    if(Math.floor(label) == label){
                                                        return numeral(label).format('0a')
                                                    }
                                                }
                                            }
                                        }]
                                    }
                                }
                            })
                        }

                    }).catch(() => {
                        this.loadListByDate = false
                    })
                })
            },

            async getLatLng(){

                return new Promise(async (resolve) => {

                    let defaultLatLng = [4.6533326, -74.083652]
                    let latLng        = defaultLatLng

                    if('geolocation' in navigator){

                        latLng = await new Promise(resolve => {
                            navigator.geolocation.getCurrentPosition((pos) => {

                                let coords = pos.coords
                                resolve([coords.latitude, coords.longitude])
                            }, () => {
                                resolve(defaultLatLng)
                            })
                        })
                    }

                    this.center  = this.l.latLng(latLng)
                    this.showMap = true

                    setTimeout(() => {

                        let roadMap = this.l.gridLayer.googleMutant({
                            maxZoom: 24,
                            type: 'roadmap'
                        }).addTo(this.$refs.map.mapObject)

                        let hybridMap = this.l.gridLayer.googleMutant({
                            maxZoom: 24,
                            type: 'hybrid'
                        })

                        this.l.control.layers({
                            'Mapa': roadMap,
                            'Satélite' : hybridMap,
                        }, {}, {
                            collapsed: false
                        }).addTo(this.$refs.map.mapObject)
                    })

                    resolve()
                })
            },

            fetchNetworks(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            filter: JSON.stringify({
                                subadmin: this.parentFilter.subadmin
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }
                    })
                })
            },

            /**
             * Obtener listado de regiones especiales
             */
            fetchRegion(){

                this.$api(this, (xhr) => {
                    xhr.get('/region/list', {
                        params: {
                            countryid: this.filter.country
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.region = data.data
                        }
                    }).catch(() => {})
                })
            },

            updateMap: _d(function({target}){
                
                if(this.inFilterMap){
                    return
                }

                let zoom   = target.getZoom()
                let bounds = target.getBounds()

                let bb = {
                    bl: bounds.getSouthWest(),
                    tr: bounds.getNorthEast()
                }

                this.filter.bb   = bb
                this.filter.zoom = zoom
                
                this.fetchData(false)

            }, 500),

            fetchListByMarker(data){

                this.selected = data
                this.noLoadMore = false

                this.filterList.type   = data.type
                this.filterList.typeid = data.uuid

                this.fetchList()
                this.fetchListByDate()
            },

            download(){

                this.loadingBtn =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter   : JSON.stringify(this.filter),
                    subadmin : JSON.stringify(this.parentFilter.subadmin),
                })

                this.$api(this, (xhr) => {
                    xhr.post('/dashboard/map/goal', filter).then((r) => {

                        let data = r.data
                        this.loadingBtn = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {
                        this.loadingBtn = false
                    })
                })
            }
        }
    }

</script>
