<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
                v-btn.no-margin(absolute, dark, fab, top, right, color='primary', @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(fixed, v-model="drawerRight", right, app, width="246")
                v-form
                    v-toolbar.grey.darken-1(flat)
                        v-text-field(v-model="filter.query", placeholder="Buscar...", dark)
                        v-icon(dark) search
                    .filters-content
                        .white--text.mb-2 {{ $t('Filtrar por') }}:
                        v-dialog.mb-3(ref='dialogStart', v-model='modalDateStart', lazy, full-width, width='290px', :return-value.sync='filter.from')
                            v-text-field(slot='activator', :label="$t('Desde')", v-model='filter.from', readonly, dark, color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.from', scrollable)
                                v-spacer
                                v-btn(flat, color='primary', @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                                v-btn(flat, color='primary', @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                        v-dialog.mb-4(ref='dialogEnd', v-model='modalDateEnd', lazy, full-width, width='290px', :return-value.sync='filter.to')
                            v-text-field(slot='activator', :label='$t("Hasta")', v-model='filter.to', readonly, dark, color="white")
                            v-date-picker(:locale="$root.currentLanguage" v-model='filter.to', scrollable)
                                v-spacer
                                v-btn(flat='', color='primary', @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                                v-btn(flat='', color='primary', @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                        v-select.mb-3(
                            v-if="$root.profile.type == 0"
                            v-model="filter.subadmin"
                            :items='subadmins'
                            item-text="names"
                            item-value="uuid"
                            :label='$t("Subadministrador")'
                            append-icon="keyboard_arrow_down" 
                            autocomplete
                            multiple
                            dark
                            color="white"
                            :filter="vFilter"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
            v-content.dashboard
                v-container.grid-list-xl(fluid)
                    h1 {{ $t('Reportes') }}
                    .stats
                        .stats-item(v-for="(item, i) in stats" :key="'s'+i")
                            v-icon.secondary--text {{ item.icon }}
                            .stats-item--content
                                strong.primary--text {{ item.number }}
                                span {{ item.text }}
                    v-divider.mt-4
                    h3 {{ $t('FUENTE DE INGRESO') }}
                    br
                    v-btn(color="primary" small @click="downloadGraph" :loading="loadingBtnGraph") {{$t('Generar reporte')}}
                    br
                    v-layout(row wrap)
                        v-flex(xs12 sm8 md6)
                            chartjs-pie(
                                ref="networkChart",
                                :datalabel="$t('Redes')",
                                :bind="true" 
                                :labels="labels", 
                                :data="datasets",
                                :height="400",
                                :option="option"
                            )
                        v-flex(xs12 sm4 md6)
                            .resume-table
                                v-data-table(
                                    ref="table"
                                    :headers='headers'
                                    :items='graph'
                                    hide-actions
                                    :custom-sort="customSort"
                                )
                                    template(slot="no-results")
                                        | {{$t('No se han encontrado resultados')}}
                                    template(slot="no-data")
                                        | {{$t('No se han encontrado resultados')}}
                                    template(slot='headers' slot-scope='props')
                                        tr
                                            th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                span.subheading {{ $t(header.text) }}
                                    template(slot='items' slot-scope='props')
                                        tr
                                            td(align="left") {{ props.item.name }}
                                            td(align="left") {{ props.item.total }}
                    v-layout.mt-5(row wrap)
                        v-flex(xs12 sm6)
                            h3 {{ $t('TOTAL DE USUARIOS') }}
                            br
                            v-btn(color="primary" small @click="filterTotalShow = !filterTotalShow") {{$t(!filterTotalShow ? 'Ver filtro' : 'Ocultar filtro')}}
                            v-layout(row wrap v-if="filterTotalShow")
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterTotal.network"
                                        :items='networks'
                                        multiple
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Red")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterTotal.country"
                                        :items='countries'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("País")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="fetchState(filterTotal.country, 'states_t')"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterTotal.state"
                                        :items='states_t'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Estado")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="fetchCity(filterTotal.state, 'cities_t')"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        small
                                        v-model="filterTotal.city"
                                        :items='cities_t'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Ciudad")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @click="zoneti=-1"
                                        @input="fetchZone(0, filterTotal.city, 'zoneti', 'zones_t', filterTotal.zones)"
                                    )
                                template(v-if="zones_t.length")
                                    v-flex(xs12 sm6 v-for="(zone, i) in zones_t" :key="'z'+i")
                                        v-select(
                                            v-model="filterTotal.zones[i]"
                                            :items='zone',
                                            item-value="uuid",
                                            item-text="name", 
                                            :label='zone[0].division', 
                                            append-icon="keyboard_arrow_down", 
                                            autocomplete
                                            :filter="vFilter" 
                                            @change="zoneti=i"
                                            @input="fetchZone(filterTotal.zones[i], filterTotal.city, 'zoneti', 'zones_t', filterTotal.zones)"
                                        )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterTotal.gender"
                                        :items='gender'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Género")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                    )
                                v-flex(xs12)
                                v-flex(xs12 sm6)
                                    v-dialog.mb-3(ref='dialogTotalStart' v-model='modalDateTotalStart' lazy full-width width='290px' 
                                        :return-value.sync='filterTotal.from')
                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filterTotal.from' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='filterTotal.from' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDateTotalStart = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogTotalStart.save(filterTotal.from)') {{$t('OK')}}
                                v-flex(xs12 sm6)
                                    v-dialog.mb-4(ref='dialogTotalEnd' v-model='modalDateTotalEnd' lazy full-width width='290px' 
                                        :return-value.sync='filterTotal.to')
                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filterTotal.to' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='filterTotal.to' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDateTotalEnd = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogTotalEnd.save(filterTotal.to)') {{$t('OK')}}
                                .btns.btns-right
                                    v-btn(color="primary" small @click="fetchGraphTotal") {{$t('Filtrar')}}
                                    v-btn(color="primary" small @click="downloadGraphTotal" :loading="loadingBtnGraphTotal") {{$t('Generar reporte')}}
                                    v-btn(color="secondary" small @click="resetTotal") {{$t('Restablecer')}}
                            v-layout(row wrap)
                                canvas(id="total-graph" height="200px")
                        v-flex(xs12 sm6)
                            h3 {{ $t('COMPARATIVO ENTRE CIUDADES') }}
                            br
                            v-btn(color="primary" small @click="filterCityShow = !filterCityShow") {{$t(!filterCityShow ? 'Ver filtro' : 'Ocultar filtro')}}
                            v-layout(row wrap v-if="filterCityShow")
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterCity.country"
                                        :items='countries'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("País")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="fetchState(filterCity.country, 'states_c')"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterCity.state"
                                        :items='states_c'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Estado")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="fetchCity(filterCity.state, 'cities_c')"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        small
                                        v-model="filterCity.city"
                                        :items='cities_c'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Ciudad")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :return-object="true"
                                    )
                                .btns.btns-right
                                    v-btn(color="primary" small @click="addCity") {{$t('Agregar ciudad')}}
                                v-flex(xs12)
                                    v-chip(color="primary" text-color="white" v-for="(city, i) in filterCity.cities" :key="'ct'+i" close @input="removeCity(i)")
                                        | {{ city.name }}
                                v-flex(xs12)
                                v-flex(xs12 sm6)
                                    v-dialog.mb-3(ref='dialogCityStart' v-model='modalDateCityStart' lazy full-width width='290px' 
                                        :return-value.sync='filterCity.from')
                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filterCity.from' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='filterCity.from' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDateCityStart = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogCityStart.save(filterCity.from)') {{$t('OK')}}
                                v-flex(xs12 sm6)
                                    v-dialog.mb-4(ref='dialogCityEnd' v-model='modalDateCityEnd' lazy full-width width='290px' 
                                        :return-value.sync='filterCity.to')
                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filterCity.to' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='filterCity.to' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDateCityEnd = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogCityEnd.save(filterCity.to)') {{$t('OK')}}
                                .btns.btns-right
                                    v-btn(color="primary" small @click="fetchGraphCity") {{$t('Filtrar')}}
                                    v-btn(color="secondary" small @click="resetCity") {{$t('Restablecer')}}
                            v-layout(row wrap)
                                .not-found.ml-4.mt-4(v-if="!filterCity.cities.length")
                                    p {{ $t('Por favor seleccione al menos una ciudad') }}
                                canvas(id="total-city" height="200px")
                    v-layout.mt-5(row wrap)
                        v-flex(xs12 sm6)
                            h3 {{ $t('CRECIMIENTO DE USUARIOS') }}
                            br
                            v-btn(color="primary" small @click="filterPerShow = !filterPerShow") {{$t(!filterPerShow ? 'Ver filtro' : 'Ocultar filtro')}}
                            v-layout(row wrap v-if="filterPerShow")
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterPer.network"
                                        :items='networks'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Red")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterPer.country"
                                        :items='countries'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("País")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="fetchState(filterPer.country, 'states_p')"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterPer.state"
                                        :items='states_p'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Estado")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="fetchCity(filterPer.state, 'cities_p')"
                                    )
                                v-flex(xs12 sm6)
                                    v-select(
                                        small
                                        v-model="filterPer.city"
                                        :items='cities_p'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Ciudad")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @click="zonepi=-1"
                                        @input="fetchZone(0, filterPer.city, 'zonepi', 'zones_p', filterPer.zones)"
                                    )
                                template(v-if="zones_p.length")
                                    v-flex(xs12 sm6 v-for="(zone, i) in zones_p" :key="'zp'+i")
                                        v-select(
                                            v-model="filterPer.zones[i]"
                                            :items='zone',
                                            item-value="uuid",
                                            item-text="name", 
                                            :label='zone[0].division', 
                                            append-icon="keyboard_arrow_down", 
                                            autocomplete
                                            :filter="vFilter"
                                            @change="zonepi=i"
                                            @input="fetchZone(filterPer.zones[i], filterPer.city, 'zonepi', 'zones_p', filterPer.zones)"
                                        )
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filterPer.gender"
                                        :items='gender'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Género")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                    )
                                v-flex(xs12)
                                v-flex(xs12 sm6)
                                    v-dialog.mb-3(ref='dialogPerStart' v-model='modalDatePerStart' lazy full-width width='290px' 
                                        :return-value.sync='filterPer.from')
                                        v-text-field(slot='activator' :label='$t("Desde")' v-model='filterPer.from' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='filterPer.from' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDatePerStart = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogPerStart.save(filterPer.from)') {{$t('OK')}}
                                v-flex(xs12 sm6)
                                    v-dialog.mb-4(ref='dialogPerEnd' v-model='modalDatePerEnd' lazy full-width width='290px' 
                                        :return-value.sync='filterPer.to')
                                        v-text-field(slot='activator' :label='$t("Hasta")' v-model='filterPer.to' readonly)
                                        v-date-picker(:locale="$root.currentLanguage" v-model='filterPer.to' scrollable)
                                            v-spacer
                                            v-btn(flat color='primary' @click.stop='modalDatePerEnd = false') {{$t('Cancelar')}}
                                            v-btn(flat color='primary' @click.stop='$refs.dialogPerEnd.save(filterPer.to)') {{$t('OK')}}
                                v-flex(xs12 sm6)
                                    v-select(
                                        v-model="filter.total_cond"
                                        :items='conditionalNumber'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Total de usuarios por")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                    )
                                    template(v-if="filter.total_cond")
                                        v-text-field(v-if="filter.total_cond != 'between'" v-model="filter.total[0]" :label="$t('Total de usuarios')")
                                        template(v-else)
                                            v-text-field(v-model="filter.total[0]" type="number" min="0" :label="$t('Total de usuarios (Desde)')")
                                            v-text-field(v-model="filter.total[1]" type="number" min="0" :label="$t('Total de usuarios (Hasta)')")
                                .btns.btns-right
                                    v-btn(color="primary" small @click="fetchGraphPercent") {{$t('Filtrar')}}
                                    v-btn(color="primary" small @click="downloadGraphPer" :loading="loadingBtnGraphPer") {{$t('Generar reporte')}}
                                    v-btn(color="secondary" small @click="resetPer") {{$t('Restablecer')}}
                            v-layout(row wrap)
                                canvas(id="total-percent" height="200px")
                    v-layout.my-5(row wrap)
                        w-map(:parentFilter="filter" @new-map="referMap" @clean-children-map="cleanChildrenReferMap")
                    v-layout.my-5(row wrap v-for="(map, i) in maps" :key="'map'+i")
                        w-map(:parentFilter="filter" @reset-map="resetReferMap" @new-map="referMap" :refer="map.item" :currentMapIndex="i" @clean-children-map="cleanChildrenReferMap")
                    
                    // Mapa de metas
                    v-layout.my-5(row wrap)
                        goal-map(:parentFilter="filter")
</template>

<script>

    import auth from 'mixins/auth'
    import load from 'scripts/load'
    import _ from 'lodash/debounce'
    import Map from 'components/Map'
    import GoalMap from 'components/dashboard/GoalMap'
    import config from 'src/config'

    export default {
        components: {
            'w-map' : Map,
            GoalMap
        },
        mixins: [auth],
        metaInfo() {
            return {
                title: this.$t('Reportes')
            }
        },
        data() {
            return {
                user: 0,
                graph: [],
                option: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    }
                },
                filterSchema: {
                    query   : '',
                    from    : null,
                    to      : null,
                    subadmin: []
                },
                filter: {},
                drawerRight: false,
                modalDateStart: false,
                modalDateEnd: false,
                headers: [
                    {
                        text: 'Nombre',
                        align: 'left',
                        value: 'name'
                    },
                    {
                        text: 'Total',
                        align: 'left',
                        value: 'total'
                    }
                ],
                subadmins       : [],
                networks        : [],
                filterTotal     : false,
                filterCityShow  : false,
                filterTotalShow : false,
                filterPerShow   : false,
                filterPer       : false,
                graphTotal      : [],
                filterTotalSchema: {
                    network   : [],
                    country   : '',
                    state     : '',
                    city      : '',
                    zones     : [],
                    gender    : '',
                    age       : '',
                    from      : null,
                    to        : null,
                    total_cond: '',
                    total     : ['','']
                },
                filterTotal : {},
                filterPerSchema: {
                    network   : [],
                    country   : '',
                    state     : '',
                    city      : '',
                    zones     : [],
                    gender    : '',
                    age       : '',
                    from      : null,
                    to        : null,
                    total_cond: '',
                    total     : ['','']
                },
                filterPer: {},
                filterCitySchema: {
                    country   : '',
                    state     : '',
                    city      : '',
                    cities    : [],
                    from      : null,
                    to        : null,
                    total_cond: '',
                    total     : ['','']
                },
                filterCity  : {},
                countries   : [],
                states_t    : [],
                cities_t    : [],
                zones_t     : [],
                zoneti      : -1,
                modalDateTotalStart : false,
                modalDateTotalEnd   : false,
                states_c    : [],
                cities_c    : [],
                modalDateCityStart : false,
                modalDateCityEnd   : false,
                states_p    : [],
                cities_p    : [],
                zones_p     : [],
                zonepi      : -1,
                modalDatePerStart : false,
                modalDatePerEnd   : false,

                cityChart  : null,
                totalChart : null,
                perChart   : null,
                loadingBtnGraph: false,
                loadingBtnGraphTotal: false,
                loadingBtnGraphPer: false,
                
                //- Mapas
                maps: []
            }
        },
        computed:{
            stats(){ 
                return [
                    { 
                        icon: 'person', 
                        text: this.$t('Usuarios registrados'), 
                        number: this.user 
                    }, 
                ]
            },
            labels(){
                return this.graph.map((item) => item.name)
            },
            datasets(){
                return this.graph.map((item) => parseFloat(item.total))
            },
            gender(){ 
                return [
                    {
                        text: this.$t('Masculino'),
                        id: 1
                    },
                    {
                        text: this.$t('Femenino'),
                        id: 0
                    },
                    {
                        text: this.$t('Otro'),
                        id: 2
                    }
                ]
            },
            ages(){ 
                return [
                    {
                        text: this.$t('0-20'),
                        id: 1
                    },
                    {
                        text: this.$t('21-40'),
                        id: 2
                    },
                    {
                        text: this.$t('41-60'),
                        id: 3
                    },
                    {
                        text: this.$t('61-80'),
                        id: 4
                    },
                    {
                        text: this.$t('Mayor a 80'),
                        id: 5
                    }
                ]
            },
        },
        watch:{
            graph:{
                handler() {
                    load.call(this, () => {
                        
                        let ref = this.$refs.networkChart
                        if(ref){
                            ref.cleanChart()
                            ref.checkOverride()
                            ref.checkSize()
                            ref.initTargetCanvas()
                        }
                    })
                },
                deep: true
            },
            filter: {
                handler(){
                    this.fetchIndicator()
                    this.fetchGraph()
                    this.fetchNetworks()

                    this.fetchGraphTotal()
                    this.fetchGraphCity()
                    this.fetchGraphPercent()
                },
                deep: true
            }
        },
        created(){

            this.fetchSubadmin()
            this.fetchNetworks()
            this.fetchCountries()

            this.fetchIndicator()
            this.fetchGraph()
            
            this.fetchGraphTotal()
            this.fetchGraphCity()
            this.fetchGraphPercent()

            this.filter      = Object.assign({}, this.filterSchema)
            this.filterTotal = Object.assign({}, this.filterTotalSchema)
            this.filterCity  = Object.assign({}, this.filterCitySchema)
            this.filterPer   = Object.assign({}, this.filterPerSchema)
        },
        methods: {

            cleanChildrenReferMap(index){

                if(index == -1){
                    this.maps = []
                }

                this.maps = this.maps.slice(0, index + 1)
            },

            referMap(item){

                if(this.maps.some(m => m.item.uuid == item.uuid)){
                    return
                }

                this.maps.push({
                    item
                })
            },

            resetReferMap(index){
                this.maps = this.maps.slice(0, index)
            },

            resetCity(){
                
                this.filterCity = Object.assign({}, this.filterCitySchema)
                this.filterCity.cities = []

                if(this.cityChart != null){
                    this.cityChart.destroy()
                }
                
                this.fetchGraphCity()
            },

            resetTotal(){
                
                this.filterTotal = Object.assign({}, this.filterTotalSchema)
                this.filterTotal.zones = []

                if(this.totalChart != null){
                    this.totalChart.destroy()
                }
                
                this.fetchGraphTotal()
            },

            resetPer(){
                
                this.filterPer = Object.assign({}, this.filterPerSchema)
                this.filterPer.zones = []

                if(this.perChart != null){
                    this.perChart.destroy()
                }
                
                this.fetchGraphPercent()
            },

            addCity(){
                this.filterCity.cities.push(this.filterCity.city)
                this.filterCity.country = ''
                this.filterCity.state   = ''
                this.filterCity.city    = ''
            },

            removeCity(i){
                this.filterCity.cities.splice(i, 1)
            },

            fetchZone(item, cityid, iter, model, filter){

                this[model] = this[iter] == -1 ? [] : this[model].slice(0, this[iter] +1)
                
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid,
                                parent: this[iter] == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if(data.data.length){
                                    filter.push('')
                                    this[model].push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchNetworks(){

                let url = '/network?list=1'

                if(this.$root.profile.type == 2){
                    url = '/network/profile?list=1'
                }

                this.$api(this, (xhr) => {
                    xhr.get(url, {
                        params: {
                            filter: JSON.stringify({
                                subadmin: this.filter.subadmin
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchCountries(){

                this.$api(this, (xhr) => {
                    xhr.get('/country').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(countryid, model = ''){

                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this[model] = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchCity(stateid, model = ''){

                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this[model] = data.data
                        }
                    }).catch(() => {})
                })
            },

            customSort(items){

                items.sort((a, b) => b.total - a.total)
                return items
            },

            fetchGraph: _(function(){

                this.$api(this, (xhr) => {
                    xhr.get('/report/graph', {
                        params: {
                            filter: JSON.stringify(this.filter),
                            subadmin : JSON.stringify(this.filter.subadmin)
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.graph = data.data
                        }
                    }).catch(() => {})
                })
            }, 500),

            fetchIndicator: _(function(){

                this.$api(this, (xhr) => {
                    xhr.get('/report/indicator', {
                        params: {
                            filter: JSON.stringify(this.filter)
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.user = data.data.user
                        }
                    }).catch(() => {})
                })
            }, 500),

            fetchGraphTotal: _(function(){

                this.$api(this, (xhr) => {
                    xhr.get('/dashboard/total', {
                        params: {
                            filter: JSON.stringify(this.filterTotal),
                            subadmin : JSON.stringify(this.filter.subadmin)
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){

                            if(this.totalChart != null){
                                this.totalChart.destroy()
                            }

                            let items = data.data
                            let ctx = document.getElementById('total-graph').getContext('2d')
                            this.totalChart = new Chart(ctx, {
                                type: 'line',
                                data: {
                                    labels: items.map((item) => item.date),
                                    datasets: [
                                        {
                                            label      : this.$t('Total de usuarios'),
                                            borderColor: '#169cb3',
                                            backgroundColor: '#169cb3',
                                            data       : items.map(item => item.total),
                                            fill       : false,
                                            lineTension: 0,
                                            pointBackgroundColor: '#169cb3',
                                            borderWidth: 1,
                                            pointBorderWidth: 0,
                                            pointRadius: 2,
                                            pointHoverRadius: 3
                                        }
                                    ]
                                }
                            })
                        }
                    }).catch(() => {})
                })
            }, 500),

            fetchGraphCity: _(function(){

                let filter = this._.cloneDeep(this.filterCity)
                let cities = this.filterCity.cities.map(c => c.uuid)
                filter.cities = cities

                this.$api(this, (xhr) => {
                    xhr.get('/dashboard/city', {
                        params: {
                            filter   : JSON.stringify(filter),
                            subadmin : JSON.stringify(this.filter.subadmin)
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){

                            let dynamicColors = function(i = 0) {

                                let colors = [
                                    '219, 29, 29',
                                    '219, 146, 29',
                                    '237, 222, 14',
                                    '45, 214, 19',
                                    '19, 214, 178',
                                    '19, 156, 214',
                                    '19, 45, 214',
                                    '120, 19, 214',
                                    '214, 19, 211',
                                    '182, 217, 187'
                                ]

                                return typeof colors[i] == 'undefined' ? colors[0] : colors[i]
                            }

                            if(this.cityChart != null){
                                this.cityChart.destroy()
                            }

                            let items = data.data
                            let ctx = document.getElementById('total-city').getContext('2d')
                            this.cityChart = new Chart(ctx, {
                                type: 'radar',
                                data: {
                                    datasets: items.map((item, i) => {
                                        
                                        let color = dynamicColors(i)
                                        return {
                                            label : item.city,
                                            borderColor: 'rgb('+color+')',
                                            pointBackgroundColor: 'rgb('+color+')',
                                            backgroundColor: 'rgb('+color+',0.2)',
                                            data: item.dates.map(item => item.total),
                                            borderWidth: 2
                                        }
                                    }),
                                    labels: items[0].dates.map((item) => item.date),
                                }
                            })
                        }
                    }).catch(() => {})
                })
            }, 500),

            fetchGraphPercent: _(function(){

                this.$api(this, (xhr) => {
                    xhr.get('/dashboard/percent', {
                        params: {
                            filter: JSON.stringify(this.filterPer),
                            subadmin : JSON.stringify(this.filter.subadmin)
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){

                            if(this.perChart != null){
                                this.perChart.destroy()
                            }

                            let items = data.data
                            let ctx = document.getElementById('total-percent').getContext('2d')
                            this.perChart = new Chart(ctx, {
                                type: 'bar',
                                data: {
                                    labels: items.map((item) => item.date),
                                    datasets: [
                                        {
                                            label      : this.$t('% de crecimiento'),
                                            borderColor: items.map((item) => {
                                                return item.percent > 0 ? 'rgba(29,204,31)' : 'rgba(242,31,31)'
                                            }),
                                            backgroundColor: items.map((item) => {
                                                return item.percent > 0 ? 'rgba(29,204,31,0.5)' : 'rgba(242,31,31,0.5)'
                                            }),
                                            data       : items.map(item => item.percent),
                                            fill       : true,
                                            borderWidth: 1,
                                            pointBorderWidth: 0,
                                            pointBackgroundColor: '#1dcc1f',
                                            pointRadius: 2,
                                            pointHoverRadius: 3
                                        },
                                        {
                                            type: 'line',
                                            label      : this.$t('Total de usuarios'),
                                            borderColor: '#169cb3',
                                            backgroundColor: '#169cb3',
                                            data       : items.map(item => item.total),
                                            fill       : false,
                                            lineTension: 0,
                                            pointBackgroundColor: '#169cb3',
                                            borderWidth: 1,
                                            pointBorderWidth: 0,
                                            pointRadius: 2,
                                            pointHoverRadius: 3
                                        }
                                    ]
                                }
                            })
                        }
                    }).catch(() => {})
                })
            }, 500),

            fetchSubadmin(){

                this.$api(this, (xhr) => {
                    xhr.get('/subadmin').then((r) => {

                        let data = r.data
                        if(data.response){
                            this.subadmins = data.data
                        }
                    }).catch(() => {})
                })
            },

            cleanFilter(){
                this.filter = Object.assign({}, this.filterSchema)
            },

            changeSort (column) {
              
                if (this.pagination.sortBy === column) {
                    this.pagination.descending = !this.pagination.descending
                } else {
                    this.pagination.sortBy = column
                    this.pagination.descending = false
                }
            },

            downloadGraph(){

                this.loadingBtnGraph =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter: JSON.stringify(this.filter),
                    subadmin : JSON.stringify(this.filter.subadmin)
                })

                this.$api(this, (xhr) => {
                    xhr.post('/report/graph', filter).then((r) => {

                        let data = r.data
                        this.loadingBtnGraph = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {
                        this.loadingBtnGraph = false
                    })
                })
            },

            downloadGraphTotal(){

                this.loadingBtnGraphTotal =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter: JSON.stringify(this.filter),
                    subadmin : JSON.stringify(this.filter.subadmin)
                })

                this.$api(this, (xhr) => {
                    xhr.post('/dashboard/total', filter).then((r) => {

                        let data = r.data
                        this.loadingBtnGraphTotal = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {
                        this.loadingBtnGraphTotal = false
                    })
                })
            },

            downloadGraphPer(){

                this.loadingBtnGraphPer =  true
                let userid = this.$root.profile.uuid

                let filter = this.$qs.stringify({
                    filter: JSON.stringify(this.filterPer),
                    subadmin : JSON.stringify(this.filter.subadmin)
                })

                this.$api(this, (xhr) => {
                    xhr.post('/dashboard/percent', filter).then((r) => {

                        let data = r.data
                        this.loadingBtnGraphPer = false

                        if(data.response){
                            let url = config.apiurl + 'download?id=' + userid + '&file=' + data.data
                            window.location.href = url
                        }
                        
                    }).catch(() => {
                        this.loadingBtnGraphPer = false
                    })
                })
            }
        }
    }
</script>