<template lang="pug">
    div
        v-dialog(v-if="modal" v-model="modal" max-width="1024" persistent)
            v-form
                v-card
                    v-card-title
                        .btns.btns-right
                            v-btn(icon color="secondary" small @click="close")
                                v-icon close
                        | {{ $t('Comportamiento en el tiempo referidor') }}
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12)
                                .text-xs-center(xs12 v-if="loadListByDateRefer")
                                    v-progress-circular(indeterminate color="primary")
                                canvas(id="list-by-date-refer" height="100px")
                            v-flex.mt-3(xs12)
                                h4.my-2 {{ $t('Listado de usuarios') }}
                                .text-xs-center(xs12 v-if="loadList")
                                    v-progress-circular(indeterminate color="primary")
                                .resume-table-map(v-else)
                                    v-data-table(
                                        ref="table"
                                        :headers='headers'
                                        :items='items'
                                        hide-actions
                                    )
                                        template(slot="no-results")
                                            | {{$t('Seleccione una ubicación en el mapa')}}
                                        template(slot="no-data")
                                            | {{$t('Seleccione una ubicación en el mapa')}}
                                        template(slot='headers' slot-scope='props')
                                            tr
                                                th(v-for='header in props.headers' :key='header.text' :align="header.align")
                                                    span.subheading {{ $t(header.text) }}
                                        template(slot='items' slot-scope='props')
                                            tr(slot="activator")
                                                td(align="left") {{ props.item.names }}
                                                td(align="left") {{ parseFloat(props.item.per).toFixed(2) }}%
                                                td(align="left")
                                                    canvas(:id="'small-chart-'+props.index" height="70px" width="120px")
                                                td(align="left") {{ props.item.country }}
                                                td(align="left") {{ props.item.state }}
                                                td(align="left") {{ props.item.city }}
                                    v-flex.text-xs-center(v-if="items.length && !noLoadMore && items.length >= limit" xs12)
                                        v-btn.primary(depressed small @click="infinite" :loading="loadInfinite") {{ $t('Ver más') }}
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="close") {{ $t('Cancelar') }}
</template>

<script>

import fn from 'mixins/fn'
import numeral from 'numeral'

export default {
    mixins: [fn],
    props: {
        filter: Object
    },
    data(){
        return {

            // Vista
            modal     : false,
            loadListByDateRefer: false,
            loadList: false,

            // Listado de fechas
            dates: [],

            // Listado de usuarios
            items: [],
            headers: [
                {
                    text: 'Nombre',
                    align: 'left',
                    value: 'names',
                    sortable: false
                },
                {
                    text: 'Crecimiento',
                    align: 'left',
                    value: 'from',
                    sortable: false
                },
                {
                    text: 'Total',
                    align: 'left',
                    value: 'from',
                    sortable: false
                },
                {
                    text: 'País',
                    align: 'left',
                    value: 'country',
                    sortable: false
                },
                {
                    text: 'Estado',
                    align: 'left',
                    value: 'state',
                    sortable: false
                },
                {
                    text: 'Ciudad',
                    align: 'left',
                    value: 'city',
                    sortable: false
                }
            ],
            loadInfinite: false,
            noLoadMore: false,
            limit : this.$root.limit,
            offset: 0,

            // Usuario actual
            current: {},
            smallCharts: {},

            // Gráficos
            listByDateReferChart: null
        }
    },
    methods: {
        async open(data){

            this.modal = true
            this.current = data
            this.smallCharts = {}

            this.fetchDates()
            this.fetchList()
        },
        close(){

            this.current = {}
            this.smallCharts = {}
            this.preload = false
            this.modal   = false
        },
        fetchDates(){

            this.loadListByDateRefer = true
            this.$api(this, (xhr) => {
                xhr.get('/dashboard/map/goal/list-by-date-refer', {
                    params:{
                        filter   : JSON.stringify(this.filter),
                        referid  : this.current.uuid
                    }
                }).then(async (r) => {
                    
                    this.loadListByDateRefer = false

                    let data = r.data
                    let dates = (data.response ? data.data : [])
                    await this.$nextTick()

                    if(dates.length){

                        if(this.listByDateReferChart != null){
                            this.listByDateReferChart.destroy()
                        }

                        let ctx = document.getElementById('list-by-date-refer').getContext('2d')
                        this.listByDateReferChart = new Chart(ctx, {
                            type: 'bar',
                            data: {
                                labels: dates.map((d) => d.label),
                                datasets: [
                                    {
                                        type       : 'line',
                                        label      : this.$t('Total de usuarios del referidor'),
                                        borderColor: '#fcb900',
                                        backgroundColor: '#fcb900',
                                        data       : dates.map(d => d.total),
                                        fill       : false,
                                        lineTension: 0.3,
                                        pointBackgroundColor: '#fcb900',
                                        borderWidth: 2,
                                        pointBorderWidth: 0,
                                        pointRadius: 3,
                                        pointHoverRadius: 4
                                    }
                                ]
                            },
                            options:{
                                scales:{
                                    xAxes:[{
                                        gridLines:{
                                            display: false
                                        }
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            userCallback(label, index, labels){
                                                if(Math.floor(label) == label){
                                                    return numeral(label).format('0a')
                                                }
                                            }
                                        }
                                    }]
                                }
                            }
                        })
                    }

                }).catch(() => {
                    this.loadListByDateRefer = false
                })
            })
        },
        fetchList(infinite = false){

            if(infinite){
                this.offset += this.limit
                this.loadInfinite = true
            }else{
                this.loadList = true
                this.items = []
                this.offset = 0
                this.$refs.infinite && this.$refs.infinite.reset()
            }

            this.$api(this, (xhr) => {
                xhr.get('/dashboard/map/goal/list-by-refer', {
                    params:{
                        filter   : JSON.stringify(this.filter),
                        referid  : this.current.uuid
                    }
                }).then(async (r) => {
                    
                    let data = r.data
                    if(!infinite) this.loadList = false

                    if(infinite){
                        this.loadInfinite = false
                        if(data.response){
                            this.items = this.items.concat(data.data);
                            this.$refs.infinite && this.$refs.infinite.loaded()
                        }else{
                            this.noLoadMore = true
                            this.$refs.infinite && this.$refs.infinite.complete()
                        }
                    }else{
                        this.items = (data.response ? data.data : [])
                    }

                    // Cargar gráficos
                    await this.$nextTick()

                    for(let i in this.items){
                        
                        let refKey = 'small-chart-' + i
                        let dat = this.items[i]

                        if(!this.smallCharts.hasOwnProperty(refKey)){
                            
                            let ctx = document.getElementById(refKey).getContext('2d')
                            
                            this.smallCharts[refKey] = new Chart(ctx, {
                                type: 'line',
                                data: {
                                    datasets: [{
                                        data: [dat.total_from, dat.total_to],
                                        borderColor: '#4caf50',
                                        backgroundColor: 'rgb(76, 175, 80, 0.5)'
                                    }],
                                    labels: [this.$t('Fecha inicial'), this.$t('Fecha final')]
                                },
                                options:{
                                    events: false,
                                    tooltips: {
                                        enabled: false
                                    },
                                    hover: {
                                        animationDuration: 0
                                    },
                                    animation: {
                                        duration: 1,
                                        onComplete: function(){

                                            this.chart.ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily)
                                            this.chart.ctx.textAlign = 'center'
                                            this.chart.ctx.textBaseline = 'bottom'
                                            
                                            this.data.datasets.forEach((dataset, i) => {
                                                let meta = this.chart.controller.getDatasetMeta(i)
                                                meta.data.forEach((line, index) => {
                                                    
                                                    let data = dataset.data[index]
                                                    this.chart.ctx.fillText(data, line._model.x, line._model.y - 5)
                                                })
                                            })
                                        },
                                    },
                                    layout: {
                                        padding: {
                                            left: 0,
                                            right: 10,
                                            top: 20,
                                            bottom: 10
                                        }
                                    },
                                    legend: {
                                        display: false
                                    },
                                    scales:{
                                        xAxes:[{
                                            gridLines:{
                                                display: false
                                            },
                                            scaleLabel:{
                                                display: false
                                            },
                                            ticks:{
                                                display: false
                                            }
                                        }],
                                        yAxes: [{
                                            gridLines:{
                                                display: false
                                            },
                                            scaleLabel:{
                                                display: false
                                            },
                                            ticks:{
                                                display: false
                                            }
                                        }]
                                    }
                                }
                            })
                        }
                    }

                }).catch(() => {
                    if(!infinite) this.loadList = false
                    if(infinite) this.loadInfinite = false
                })
            })
        },
        infinite(){
            setTimeout(() => this.fetchList(true) , 1)
        }
    }
}

</script>
